﻿import { environment } from '../../../environments/environment';
import {} from '../../gestion/invoicing/invoicing.module';

class BaseUrls {
    public static webAppHost = environment.trampWeb;

    public static pickupsHost = environment.driverVHost;

    public static localApiRest = environment.backendApi;
    public static environmentGrm = environment.backendApi + '/api/enviroment/';
    public static configurationGrm = environment.backendApi + '/api/configuration/';
    public static apiGrm = environment.backendApi + '/api/validation/';
    public static documentationGrm = environment.backendApi + '/api/documentation/';
    public static documentationGrmRomedocs = window['documentationApi'] + '/RomeDocs/';
    public static environment = environment.backendApi;
}
class CustomerProvider {
    public static GetByVat = BaseUrls.environmentGrm + '/customer/GetByVat';
    public static GetByBranchName = BaseUrls.environment + '/api/CustomerProvider/GetAllCustomersAsync';
    public static GetByFiscalName = BaseUrls.environmentGrm + '/customer/GetByFiscalName';
    public static GetCustomerListByCodes = BaseUrls.environmentGrm + '/customer/GetCustomerListByCodes';
    public static Default = BaseUrls.environmentGrm + '/customer/GetByVat';
    public static GetByVatAndEntityCode = '/api/CustomerProvider/GetAllCustomersByVatOrEntityCodeAsync';
    public static GetByBranchAndFiscalName = '/api/CustomerProvider/GetAllCustomersByNameOrFiscalNameAsync';
}
class DynamicModulePaths {
    public static Provider = 'app/gestion/provider/provider.module';
    public static Project = 'app/gestion/project/project.module';
    public static Person = 'app/gestion/person/person.module';
    public static Vessel = 'app/gestion/vessel/vessel.module';
    public static TypeService = 'app/gestion/typeservice/typeservice.module';
    public static Reservation = 'app/gestion/reservation/reservation.module';
    public static Quotation = 'app/gestion/quotation/quotation.module';
    public static Pricelist = 'app/gestion/pricelist/pricelist.module';
    public static Flight = 'app/gestion/flight/flight.module';
    public static Activity = 'app/gestion/activity/activity.module';
    public static Notification = 'app/gestion/notification/notification.module';
    public static Rental = 'app/gestion/rental/rental.module';
    public static Inventory = 'app/gestion/inventory/inventory.module';
    public static Incidence = 'app/gestion/incidence/incidence.module';
    public static Address = 'app/gestion/address/address.module';
    public static officialOrganizations = 'app/gestion/official-organization/official-organization.module';
    public static invoicing = 'app/gestion/invoicing/invoicing.module';
    public static baseRate = 'app/gestion/base-rate/base-rate.module';
    public static onsigner = 'app/gestion/crew-management/crew-management.module';
    public static offsigner = 'app/gestion/crew-management/crew-management.module';
}
export class Global {
    public static DEFAULT_TYPEDOC = 36;
    public static DEFAULT_CURRENCY = 1;
    public static DEFAULT_CURRENCY_CODE = 'EUR';
    public static MODULE_PATHS = DynamicModulePaths;
    public static ADMIN_ZONE_URI = 'desktop';
    public static PROVIDER_USER_GROUP = 'CSGPROVTRAMP';
    public static DELEGATED_PROVIDER_USER_GROUP = 'CSGPROVDELTRAMP';
    public static CHOFER_USER_GROUP = 'CSGCHOFTRAMP';
    public static CLIENT_USER_GROUP = 'CSGCLITRAMP';
    public static DEVELOP_MODE = true;
    public static BASE_API_URL_DEVELOP = BaseUrls.localApiRest;
    public static BASE_API_URL_PROD = BaseUrls.localApiRest;
    public static APP_URL = BaseUrls.webAppHost;
    public static APP_FILES = BaseUrls.webAppHost + '/app/Assets';
    public static BASE_USER_ENDPOINT = 'api/userapi/';
    public static GRUPO = 'Romeu Group';
    public static URL_GRUPO = 'http://www.gruporomeu.com/';
    public static URL_DEFAULT_SIDEBAR = 'http://www.canarship.eu';
    public static IMG_DEFAULT_SIDEBAR = 'assets/img/logos/ROME_477x140.png';
    public static IMG_LOGO_ROOT = 'assets/img/logos/';
    public static LIMIT_DEFAULT_PAGEDITEMS = 10;
    public static PAGES_ITEMS_RESERVATION = 20;
    public static LIMIT_DEFAULT_COMBO_FILTER = 10;
    public static BASE_TRIPULANTE_ENDPOINT = 'api/Crew/';
    public static BASE_MOVIMIENTO_TRIPULANTE_ENDPOINT = 'api/SignerMovement/';
    public static BASE_VUELO_MOVIMIENTO_TRIPULANTE_ENDPOINT = 'api/SignerFlight/';
    public static BASE_GTRIPULANTE_ENDPOINT = 'api/Crew/';
    public static BASE_BARCO_ENDPOINT = 'api/Vessel/';
    public static BASE_PROYECTO_ENDPOINT = 'api/Project/';
    public static BASE_BARCO_PROYECTO_ENDPOINT = 'api/VesselProject/';
    public static BASE_PROVEEDOR_ENDPOINT = 'api/CustomerProviderCSG/';
    public static BASE_SERVICIO_GENERICO_PROVEEDOR_ENDPOINT = 'api/ProviderServiceGenTariff/';
    public static BASE_SERVICIO_CLIENTE_PROVEEDOR_ENDPOINT = 'api/CustomerServiceTariff/';
    public static BASE_ESCALA_ENDPOINT = 'api/Voyage/';
    public static BASE_ADD_EXISTING_VOYAGE = 'api/Voyage/AddExistingVoyageToProject';
    public static BASE_MOVIMIENTO_ESCALA_ENDPOINT = 'api/Movement/';
    public static BASE_PUERTO_ENDPOINT = 'api/Port/';
    public static BASE_PERSONA_ENDPOINT = 'api/Person/';
    public static BASE_CLIENTE_CSG_ENDPOINT = 'api/CustomerProviderCSG/';
    public static BASE_CLIENTE_ENDPOINT = 'api/CustomerProvider/';
    public static BASE_VUELO_ENDPOINT = 'api/Flight/';
    public static BASE_GRUPO_ENDPOINT = 'api/Group/';
    public static BASE_GRUPO_TRIPULANTE_ENDPOINT = 'api/GroupCrew/';
    public static BASE_SERVICIO_ENDPOINT = 'api/Service/';
    public static BASE_TIPOSERVICIO_ENDPOINT = 'api/ServiceType/';
    public static BASE_GENERAL_TIPOS_ENDPOINT = 'api/GeneralType/';
    public static BASE_RESERVA_ENDPOINT = 'api/Reservation/';
    public static BASE_RESERVATION_DRIVER_ENDPOINT = 'api/ReservationDriver/';
    public static BASE_GENERIC_RESERVA_ENDPOINT = 'api/ReservationGeneric/';
    public static BASE_RESERVA_FALUA_ENDPOINT = 'api/ReservationBoatVoyage/';
    public static BASE_RESERVA_MEET_AND_GREET = 'api/ReservationMeetAndGreet/';
    public static BASE_RESERVA_AUTOINVOICE = 'api/ReservationAutoInvoice/';
    public static BASE_RESERVA_DOCUMENT_FEE = 'api/ReservationDocumentFee/';
    public static BASE_SERVICIO_RESERVADO_ENDPOINT = 'api/ReservationService/';
    public static BASE_TARIFA_SERVICIO_RESERVADO_ENDPOINT = 'api/ReservationServiceTariff/';
    public static BASE_CREW_SERVICIO_RESERVADO_ENDPOINT = 'api/CrewReservation/';
    public static BASE_COTIZACION_ENDPOINT = 'api/Quotation/';
    public static BASE_QUOTATION_INTEGRATION_ENDPOINT = 'api/QuotationIntegration/';
    public static BASE_SERVICIO_COTIZACION_ENDPOINT = 'api/ServiceQuotation/';
    public static BASE_TARIFA_SERVICIO_COTIZACION_ENDPOINT = 'api/ServiceQuotationTariff/';
    public static BASE_TRIPULANTE_COTIZACION_ENDPOINT = 'api/CrewServQuotation/';
    public static BASE_HISTORICO_COTIZACION_ENDPOINT = 'api/QuotationHistory/';
    public static BASE_SEGMENTOS_ENDPOINT = 'api/Segment/';
    public static BASE_TIPOS_SEGMENTOS_ENDPOINT = 'api/TypeSegment/';
    public static BASE_PRICELIST_ENDPOINT = 'api/Price/';
    public static BASE_PRICELIST_SERVICE_ENDPOINT = 'api/ServicePrice';
    public static BASE_HISTORICO_PRICELIST_ENDPOINT = 'api/PriceHistory/';
    public static BASE_ACTIVIDAD_ENDPOINT = 'api/LogSystem/';
    public static BASE_DOCUMENTOS_ENDPOINT = 'api/Doc/';
    public static BASE_SYNCFUSION_ENDPOINT = '/api/Doc';
    public static BASE_ROME_TIPOS_DOCUMENTOS_ENDPOINT = 'api/TypeDoc/';
    public static BASE_TIPOS_DOCUMENTOS_ENDPOINT = 'api/TypeDocDivision/';
    public static BASE_TIPOS_DOCUMENTOS_TIPO_SERVICIO_ENDPOINT = 'api/TypeDocServType/';
    public static BASE_TIPOS_DOCUMENTOS_SUPER_TIPO_SERVICIO_ENDPOINT = 'api/TypeDocSuperServType/';
    public static BASE_TIPOS_DOCUMENTOS_COTIZACION_ENDPOINT = 'api/TypeDocQuotation/';
    public static BASE_TIPOS_DOCUMENTOS_PRICELIST_ENDPOINT = 'api/TypeDocPrice/';
    public static BASE_TIPOS_DOCUMENTOS_ALQUILERES_ENDPOINT = 'api/TypeDocRental/';
    public static BASE_TIPOS_DOCUMENTOS_INVENTARIO_ENDPOINT = 'api/TypeDocStock/';
    public static BASE_NOTIFICACIONES_ENDPOINT = 'api/Notification/';
    public static BASE_USER_GRUPOS_NOTIFICACIONES_ENDPOINT = 'api/UserGroupNotif/';
    public static BASE_INVENTARIO_ENDPOINT = 'api/Stock/';
    public static BASE_CATEGORIAS_INVENTARIO_ENDPOINT = 'api/CategoryStock/';
    public static BASE_ALQUILER_ENDPOINT = 'api/Rental/';
    public static BASE_CREW_ALQUILER_ENDPOINT = 'api/CrewRental/';
    public static BASE_SERVICIO_ALQUILER_ENDPOINT = 'api/ServiceRental/';
    public static BASE_STOCK_ALQUILER_ENDPOINT = 'api/StockRental/';
    public static BASE_OFFICIAL_ORGANIZATIONS_ENDPOINT = 'api/OfficialOrganization/';
    public static BASE_INVOICING_ENDPOINT = 'api/invoice/';
    public static BASE_DOCUMENTATION_ENDPOINT = 'api/Doc/';
    public static BASE_BASE_RATE = 'api/baseRate/';
    public static BASE_FILE_CONFIGURATION_ENDPOINT = 'api/FileConfiguration';
    public static BASE_PROJECT_CATEGORY_ENDPOINT = 'api/ProjectCategory/';
    public static BASE_LINE_ENDPOINT = 'api/Line';
    public static BASE_SENT_PROVIDER_EMAIL = 'api/ReservationProviderEmail/';

    public static BASE_REFERNCE_CONTROLLER_ENDPOINT = 'api/Reference/';
    public static BASE_REPORT_ENDPOINT = 'api/report/';
    public static BASE_REPORT_VISA_ENDPOINT = 'api/report/visa';
    public static BASE_REPORT_INVOICE_ENDPOINT = 'api/report/invoice';
    public static BASE_INVOICE_GENERATE_ENDPOINT = 'api/invoice/generate-concepts';
    public static BASE_REPORT_LOGOKTB_ENDPOINT = 'api/report/logoktb';
    public static BASE_REPORT_LOISPA_ENDPOINT = 'api/report/loi';
    public static BASE_REPORT_LOIENG_ENDPOINT = 'api/report/loienglish';
    public static BASE_REPORT_ONSIGNERS_ENDPOINT = 'api/report/onsigners';
    public static BASE_REPORT_ONSIGNERS_COVID_ENDPOINT = 'api/report/onsigners-covid';
    public static BASE_REPORT_OFFSIGNERS_ENDPOINT = 'api/report/offsigners';
    public static BASE_REPORT_HOTEL_ENDPOINT = 'api/report/hotel';
    public static BASE_REPORT_SCHENGUEN_ENDPOINT = 'api/report/schengen';
    public static BASE_REPORT_TRANSFER_ENDPOINT = 'api/report/transfer';
    public static BASE_REPORT_VISADOALGECIRAS_ENDPOINT = 'api/report/visadoalgeciras';
    public static BASE_REPORT_QUOTATION_ENDPOINT = 'api/report/quotation';
    public static BASE_REPORT_RESERVATION_QUOTATION_ENDPOINT = 'api/report/reservation-quotation';
    public static BASE_REPORT_RESERVATION_SERVICE_SHEET_ENDPOINT = 'api/report/reservation-servicesSheet';
    public static BASE_REPORT_PRICELIST_ENDPOINT = 'api/report/pricelist';
    public static BASE_REPORT_RESERVATION_ENDPOINT = 'api/report/crewsignatures';
    public static BASE_REPORT_CARTIFICADOR_ENDPOINT = 'api/report/cartificador';
    public static BASE_REPORT_RESERVATION_SUMMARY_ENDPOINT = 'api/report/reservationsummary';
    public static BASE_REPORT_RESERVATION_DOSSIER_ENDPOINT = 'api/report/reservationdossier';
    public static BASE_REPORT_RESERVATION_PURCHASE_ORDER = 'api/report/GenerateReservationListPurchaseOrder';
    public static BASE_REPORT_RESERVATION_PURCHASE_ORDER_GRUPAL = 'api/report/GenerateReservationListPurchaseOrderGrupal';

    public static BASE_DIRECCIONES_ENDPOINT = 'api/Contact/';
    public static BASE_INCIDENCIAS_ENDPOINT = 'api/Incident/';
    public static BASE_INVOICE_ENDPOINT = 'api/invoice/';
    public static BASE_TIPOS_INCIDENCIAS_ENDPOINT = 'api/IncidentType/';
    public static BASE_CHOFERS_ENDPOINT = 'api/Driver/';
    public static BASE_PERMISOS_USER_ENDPOINT = 'api/UserPermission/';
    public static BASE_EXCHANGE_ENDPOINT = 'api/ExchangeValue/';
    public static BASE_DIVISAS_ENDPOINT = 'api/Currency/';
    public static BASE_AUDIT_ENDPOINT = 'api/audit/';

    public static BASE_PLANTILLAS_TARIFA_ENDPOINT = 'api/TemplateTariff/';
    public static BASE_ETIQUETAS_ENDPOINT = 'api/TariffTag/';

    public static BASES_API_URL_CLIENTE_PROVEEDOR = CustomerProvider;
    public static BASE_API_URL_PAISES = BaseUrls.environmentGrm + 'country/Get';
    public static BASE_API_URL_PUERTOS = environment.backendApi + '/port';
    public static BASE_API_URL_DIVISIONES = BaseUrls.environmentGrm + 'division/GetAll';
    public static BASE_API_URL_DIVISION = BaseUrls.environmentGrm + 'division/GetByID';
    public static BASE_API_URL_LOGO_DIVISION = BaseUrls.environmentGrm + 'Division/GetLogos';
    public static BASE_API_URL_USER_VALIDATION = BaseUrls.apiGrm + 'Token';
    public static BASE_API_URL_LOGGED_USER_CAMBIA_DIVISION = BaseUrls.apiGrm + 'ChangeDivision';
    public static BASE_API_URL_LOGGED_USER_DIVISIONES = BaseUrls.configurationGrm + 'user/Divisions';
    public static BASE_API_URL_DIVISIONS_LOGO = BaseUrls.environmentGrm + 'division/GetLogos';
    public static BASE_API_URL_LOGGED_USER = BaseUrls.apiGrm + 'User';
    public static BASE_API_URL_LOGGED_USER_MENU = BaseUrls.configurationGrm + 'user/Menu';
    public static BASE_API_URL_USUARIOS = BaseUrls.configurationGrm + 'user/GetByCodeOrDescription';
    public static BASE_API_URL_USUARIOSBYCODE = BaseUrls.configurationGrm + 'user/GetUsersByCode';
    public static BASE_API_URL_USER_BY_GROUP_CODE_LIST = BaseUrls.configurationGrm + 'user/GetUsersByGroupCodeList';
    public static BASE_API_URL_LANGUAGES = BaseUrls.configurationGrm + 'Language/';

    public static BASE_API_URL_DOCUMENTATION = BaseUrls.documentationGrm;
    public static BASE_API_GRM_ROMEDOCS = BaseUrls.documentationGrmRomedocs;

    public static BASE_API_URL_DOCUMENTATION_DELETE_DOC = BaseUrls.documentationGrm + '/Delete';

    public static APP_ID = 3;
    public static RESERVATION_CHANGESTATUS = BaseUrls.localApiRest + '/api/Reservation/ChangeStatus';
    public static DRIVER_BASE_ENDPOINT = BaseUrls.localApiRest + '/api/Driver/';
    public static DriverEndpoint = BaseUrls.localApiRest + '/api/driver';
    public static LoggingEndpoint = BaseUrls.localApiRest + '/api/logging';
    public static SendException = BaseUrls.localApiRest + '/api/logging/api/logging/SendException';
    public static WebhooksEndpoint = BaseUrls.localApiRest + '/api/webhooks/';
    public static CUSTOMER_PROVIDER_BASE_ENDPOINT = BaseUrls.localApiRest + '/api/CustomerProvider/';
}
